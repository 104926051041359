import {Auth} from '@aws-amplify/auth'
import {Hub} from '@aws-amplify/core'

import {createState as createHookState, State as HookState} from '@hookstate/core'

import {enumerate, isString, newUUID, resolvablePromise, ResolvablePromise} from '@peachy/utility-kit-pure'
import {createNetwork, StageNetworkDefinition, StageState, StageTransitionNetwork} from '@peachy/utility-kit-browser'

import {IamUserService} from './IamUserService'
import {CognitoMemberUserAttributes} from '@peachy/iam-pure'

export type IamApi = Pick<typeof Auth,
    'currentAuthenticatedUser' |
    'changePassword' |
    'completeNewPassword' |
    'confirmSignIn' |
    'confirmSignUp' |
    'verifyUserAttribute' |
    'verifyUserAttributeSubmit' |
    'updateUserAttributes' |
    'forgotPassword' |
    'forgotPasswordSubmit' |
    'resendSignUp' |
    'signIn' |
    'signOut' |
    'signUp'>


type SignUpArgs = {
    username: string
    password: string
    phone: string
    firstName: string
    lastName: string
}

export type IamService = {
    signBackIn(): void
    signIn(username: string, password: string): Promise<void>
    signUp(args: SignUpArgs): Promise<void>
    signOut(): void

    forgottenPassword(): void
    requestForgottenPasswordCode(username: string): void
    resetForgottenPassword(username: string, newPassword: string, resetCode: string): void
    cancelForgottenPasswordReset(): void

    resetNewPassword(username: string, oldPassword: string, newPassword: string): void
    cancelNewPasswordReset(): void

    confirmEmailConfirmationCode(username: string, password: string, confirmationCode: string): void
    requestEmailConfirmationCode(username: string): void
    cancelEmailConfirmation(): void

    checkUsername(username: string): void
    getCheckedUsernames(): HookState<CheckedUsernames>

    // only available once signed in and email verified
    updatePhoneNumber(phone: string): void
    requestPhoneConfirmationCode(): void
    cancelPhoneConfirmation(): void
    confirmPhoneConfirmationCode(confirmationCode: string): void

    getCurrentIamStage(): HookState<IamStageState>

    isBusy(): boolean
}

// the stages (or states) the service can be in at any point -  as in a state transition network,
// but using the term 'stage' given the overloaded meaning of state in react worlds!
export const IamStageNames = enumerate([
    'Unknown',
    'SigningBackIn',

    'Anon',

    'SigningIn',
    'SigningUp',
    'SigningOut',

    'SignedIn',

    'ForgottenPassword',
    'RequestingForgottenPasswordCode',
    'AwaitingForgottenPasswordCode',
    'ResettingForgottenPassword',
    'AwaitingNewPassword',
    'ResettingNewPassword',
    'ChangingPassword',

    'AwaitingEmailConfirmationCode',
    'ConfirmingEmail',
    'RequestingEmailConfirmationCode',

    'AwaitingPhoneNumberUpdate',
    'UpdatingPhone',
    'AwaitingPhoneConfirmationCode',
    'ConfirmingPhoneCode',
    'RequestingPhoneConfirmationCode',
    'CheckingUsername',

] as const)

// the stages as type union
export type IamStageName = keyof typeof IamStageNames

export const IamBusyStages: IamStageName[] = [
    IamStageNames.SigningBackIn,
    IamStageNames.SigningIn,
    IamStageNames.SigningUp,
    IamStageNames.SigningOut,


    IamStageNames.RequestingForgottenPasswordCode,
    IamStageNames.ResettingForgottenPassword, // anon user driven password change
    IamStageNames.ResettingNewPassword, // forced password change
    IamStageNames.ChangingPassword, // logged on user driven password change

    IamStageNames.RequestingEmailConfirmationCode,
    IamStageNames.ConfirmingEmail,

    IamStageNames.UpdatingPhone,
    IamStageNames.ConfirmingPhoneCode,
    IamStageNames.RequestingPhoneConfirmationCode,
    IamStageNames.CheckingUsername,
]

export const IamEmailStages: IamStageName[] = [
    IamStageNames.AwaitingEmailConfirmationCode,
    IamStageNames.ConfirmingEmail,
    IamStageNames.RequestingEmailConfirmationCode,
]

export const IamSignInStages: IamStageName[] = [
    IamStageNames.Anon,
    IamStageNames.SigningIn,
]

export const IamPhoneStages: IamStageName[] = [
    IamStageNames.AwaitingPhoneNumberUpdate,
    IamStageNames.UpdatingPhone,
    IamStageNames.AwaitingPhoneConfirmationCode,
    IamStageNames.ConfirmingPhoneCode,
    IamStageNames.RequestingPhoneConfirmationCode,
]

export const IamPasswordStages: IamStageName[] = [
    IamStageNames.ForgottenPassword,
    IamStageNames.RequestingForgottenPasswordCode,
    IamStageNames.AwaitingForgottenPasswordCode,
    IamStageNames.ResettingForgottenPassword,
    IamStageNames.AwaitingNewPassword,
    IamStageNames.ResettingNewPassword,
    IamStageNames.ChangingPassword,
]


export type IamStageData = {
    errorMessage: string,
    errorCode: string,
}


export type IamStageState = StageState<IamStageName, IamStageData>

type CheckedUsernames = {
    [username: string]: boolean
}

// factory function for service instance
export function createIamService(iamApi: IamApi, iamUserService?: IamUserService): IamService {

    const checkedUsernames = createHookState<CheckedUsernames>({})

    // the internal stage transition network definition

    const iamNetDef: StageNetworkDefinition<IamStageName, IamStageData> = {
        Unknown: {
            signBackIn() {
                onSignBackIn()
                return iamNetDef.SigningBackIn
            }
        },
        SigningBackIn: {
            success: (user) => iamNetDef.SignedIn,
            requireEmailConfirmationCode: () => iamNetDef.AwaitingEmailConfirmationCode,
            requirePhoneNumberUpdate: () => iamNetDef.AwaitingPhoneNumberUpdate,
            failure: (error) => iamNetDef.Anon,
        },

        Anon: {
            signIn(username: string, password: string, promise?: ResolvablePromise<void>) {
                onSignIn(username, password, promise)
                return iamNetDef.SigningIn
            },
            signUp(args: SignUpArgs, promise?: ResolvablePromise<void>) {
                onSignUp(args, promise)
                return iamNetDef.SigningUp
            },
            checkUsername(username: string) {
                return onCheckUsername(username)
            },
            forgottenPassword: () => iamNetDef.ForgottenPassword,
        },


        CheckingUsername: {
            success: () => iamNetDef.Anon,
            failure: (error) => [iamNetDef.Anon, formatError(error)],
        },
        SigningIn: {
            success: (user) => iamNetDef.SignedIn,
            requireNewPassword: () => iamNetDef.AwaitingNewPassword,
            requireEmailConfirmationCode: () => iamNetDef.AwaitingEmailConfirmationCode,
            requirePhoneNumberUpdate: () => iamNetDef.AwaitingPhoneNumberUpdate,
            failure: (error) => [iamNetDef.Anon, formatError(error)],
        },

        SigningUp: {
            success: () => iamNetDef.SignedIn,
            requireEmailConfirmationCode: () => iamNetDef.AwaitingEmailConfirmationCode,
            requirePhoneNumberUpdate: () => iamNetDef.AwaitingPhoneNumberUpdate,
            failure: (error) => {

                switch (error.code) {
                    case 'InvalidParameterException' : {
                        return [iamNetDef.Anon, formatError('Hmm, looks like you entered an invalid parameter')]
                    }
                    case 'UsernameExistsException' : {
                        return [iamNetDef.Anon, formatError('This email address is already connected to an account')]
                    }
                    case 'InvalidPasswordException': {
                        return [iamNetDef.Anon, formatError(error)]
                    }
                    default: {
                        return [iamNetDef.Anon, formatError(error)]
                    }
                }
            },
        },
        SignedIn: {
            signOut() {
                onSignOut()
                return iamNetDef.SigningOut
            },
            changePassword(oldPassword: string, newPassword: string) {
                onChangePassword(oldPassword, newPassword)
                return iamNetDef.ChangingPassword
            }
        },
        SigningOut: {
            success: () => iamNetDef.Anon,
            failure: (error) => [iamNetDef.Anon, formatError(error)],
        },


        ForgottenPassword: {
            requestForgottenPasswordCode(username: string) {
                onRequestForgottenPasswordCode(username)
                return iamNetDef.RequestingForgottenPasswordCode
            },
            cancelForgottenPasswordReset: () => iamNetDef.Anon,
        },

        RequestingForgottenPasswordCode: {
            success: () => iamNetDef.AwaitingForgottenPasswordCode,
            failure: (error) => [iamNetDef.ForgottenPassword, formatError(error)],
        },

        AwaitingForgottenPasswordCode: {
            resetForgottenPassword(username: string, newPassword: string, resetCode: string) {
                onResetForgottenPassword(username, newPassword, resetCode)
                return iamNetDef.ResettingForgottenPassword
            },
            requestForgottenPasswordCode(username: string) {
                onRequestForgottenPasswordCode(username)
                return iamNetDef.RequestingForgottenPasswordCode
            },
            cancelForgottenPasswordReset: () => iamNetDef.Anon,
        },

        ResettingForgottenPassword: {
            success(username: string, password: string) {
                onSignIn(username, password)
                return iamNetDef.SigningIn
            },
            failure: (error) => [iamNetDef.AwaitingForgottenPasswordCode, formatError(error)],
        },


        AwaitingNewPassword: {
            resetNewPassword: (username: string, oldPassword: string, newPassword: string) => {
                onResetNewPassword(username, oldPassword, newPassword)
                return iamNetDef.ResettingNewPassword
            },
            cancelNewPasswordReset: () => iamNetDef.Anon,
        },

        ResettingNewPassword: {
            success(username: string, password: string) {
                onSignIn(username, password)
                return iamNetDef.SigningIn
            },
            failure: (error) => [iamNetDef.AwaitingNewPassword, formatError(error)],
        },


        ChangingPassword: {
            success(username: string, password: string) {
                onSignIn(username, password)
                return iamNetDef.SignedIn // todo is this correct or are you signed out?
            },
            failure: (error) => [iamNetDef.SignedIn, formatError(error)],
        },


        AwaitingEmailConfirmationCode: {
            confirmEmailConfirmationCode(username: string, password: string, confirmationCode: string) {
                onConfirmEmailConfirmationCode(username, password, confirmationCode)
                return iamNetDef.ConfirmingEmail
            },
            requestEmailConfirmationCode(username: string) {
                onRequestEmailConfirmationCode(username)
                return iamNetDef.RequestingEmailConfirmationCode
            },
            cancelEmailConfirmation: () => iamNetDef.Anon
        },
        ConfirmingEmail: {
            success(username: string, password: string) {
                onSignIn(username, password)
                return iamNetDef.SigningIn
            },
            failure: (error) => {
                return [iamNetDef.AwaitingEmailConfirmationCode, formatError(error)]
            },
        },
        RequestingEmailConfirmationCode: {
            success: () => iamNetDef.AwaitingEmailConfirmationCode,
            failure: (error) => [iamNetDef.AwaitingEmailConfirmationCode, formatError(error)],
        },


        AwaitingPhoneNumberUpdate: {
            updatePhoneNumber(phone: string) {
                onUpdatePhone(phone)
                return iamNetDef.UpdatingPhone
            },
        },

        AwaitingPhoneConfirmationCode: {
            confirmPhoneConfirmationCode(confirmationCode: string) {
                onConfirmPhoneConfirmationCode(confirmationCode)
                return iamNetDef.ConfirmingPhoneCode
            },
            requestPhoneConfirmationCode() {
                onRequestPhoneConfirmationCode()
                return iamNetDef.RequestingPhoneConfirmationCode
            },
            cancelPhoneConfirmation: () => iamNetDef.AwaitingPhoneNumberUpdate
        },
        UpdatingPhone: {
            success() {
                return iamNetDef.AwaitingPhoneConfirmationCode
            },
            failure: (error) => [iamNetDef.AwaitingPhoneNumberUpdate, formatError(error)],
        },
        ConfirmingPhoneCode: {
            success: () => iamNetDef.SignedIn,
            failure: (error) => [iamNetDef.AwaitingPhoneConfirmationCode, formatError(error)],
        },
        RequestingPhoneConfirmationCode: {
            success: () => iamNetDef.AwaitingPhoneConfirmationCode,
            failure: (error) => {
                return [iamNetDef.AwaitingPhoneConfirmationCode, formatError(error)]
            },
        },
    }

    // the actual runtime network interface {dispatch(...), getCurrentState()}
    const iamStageNetwork: StageTransitionNetwork<IamStageName, IamStageData> = createNetwork(iamNetDef, iamNetDef.Unknown)

    // bunch of event handler logic extracted to de-clutter the definition above
    const onSignBackIn = () => {
        // todo Darren was going to look at these events
        iamApi.currentAuthenticatedUser()
            .then((user) => {
                Hub.dispatch('peachyIam', {event: 'signBackIn', data: {attributes: user.attributes}})
                const isPhoneVerified = user.attributes?.phone_number_verified
                if (isPhoneVerified) {
                    iamStageNetwork.dispatch(iamNetDef.SigningBackIn.success)
                } else {
                    iamStageNetwork.dispatch(iamNetDef.SigningBackIn.requirePhoneNumberUpdate)
                }

                iamStageNetwork.dispatch(iamNetDef.SigningBackIn.success, user)
            })
            .catch(signInError => {

                signInError.code === 'UserNotConfirmedException'
                    ? iamStageNetwork.dispatch(iamNetDef.SigningBackIn.requireEmailConfirmationCode)
                    : iamStageNetwork.dispatch(iamNetDef.SigningBackIn.failure, signInError)
            })
    }


    const onSignIn = (username: string, password: string, promise?: ResolvablePromise<void>) => {

        iamApi.signIn(username, password)
            .then((user) => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

                    iamStageNetwork.dispatch(iamNetDef.SigningIn.requireNewPassword)
                    promise?.reject('Must change password')

                } else if (!user.attributes?.phone_number_verified) {

                    iamStageNetwork.dispatch(iamNetDef.SigningIn.requirePhoneNumberUpdate)
                    promise?.reject('Must confirm phone number')

                } else {

                    iamStageNetwork.dispatch(iamNetDef.SigningIn.success, user)
                    promise?.resolve()
                }

            })
            .catch(signInError => {

                if (signInError.code === 'UserNotConfirmedException') {

                    iamStageNetwork.dispatch(iamNetDef.SigningIn.requireEmailConfirmationCode)

                    promise?.reject('Must confirm email')

                } else if (signInError.code === 'PasswordResetRequiredException') {

                    iamStageNetwork.dispatch(iamNetDef.SigningIn.requireNewPassword)
                    promise?.reject('Must reset password')
                } else {
                    if (iamUserService && hasTemporaryPasswordExpired(signInError)) {
                        iamUserService.reInvite(username).catch(e => {
                            console.log(`Failed to reInvite ${username}`)
                            console.error(e)
                        })
                    }

                    promise?.reject(signInError)
                    iamStageNetwork.dispatch(iamNetDef.SigningIn.failure, signInError)
                }
            })
    }

    const onSignUp = ({ username, password, phone, firstName, lastName }: SignUpArgs, promise?: ResolvablePromise<void>) => {
        const attributes: Pick<CognitoMemberUserAttributes, 'custom:initial_phone'|'given_name'|'family_name'> = {
            'custom:initial_phone': phone,
            given_name: firstName,
            family_name: lastName
        }
        iamApi.signUp({username, password, attributes})
            .then(user => {
                iamStageNetwork.dispatch(iamNetDef.SigningUp.requireEmailConfirmationCode)
                promise?.resolve()

            })
            .catch(signUpError => {

                if (signUpError.code === 'UsernameExistsException') {
                    iamApi.signIn(username, password)
                        .then(user => {
                            const isPhoneVerified = user.attributes?.phone_number_verified
                            if (isPhoneVerified) {
                                iamStageNetwork.dispatch(iamNetDef.SigningUp.success)
                            } else {
                                iamStageNetwork.dispatch(iamNetDef.SigningUp.requirePhoneNumberUpdate)
                            }

                            promise?.resolve()

                        })
                        .catch(signInError => {
                            if (signInError.code === 'UserNotConfirmedException') {
                                iamStageNetwork.dispatch(iamNetDef.SigningUp.requireEmailConfirmationCode)
                                promise?.resolve()
                            } else {
                                iamStageNetwork.dispatch(iamNetDef.SigningUp.failure, signUpError)
                                promise?.reject(signInError)
                            }
                        })
                } else {

                    iamStageNetwork.dispatch(iamNetDef.SigningUp.failure, signUpError)
                    promise?.reject(signUpError)
                }
            })
    }

    const onCheckUsername = (username: string) => {

        if (checkedUsernames.value[username] === undefined) {

            iamApi.signIn(username, newUUID())
                .then(() => {
                    throw `Congratulations you've won the biggest lottery in the universe!!!`
                })
                .catch(error => {
                    const usableResult = ['UserNotFoundException', 'NotAuthorizedException', 'UserNotConfirmedException'].includes(error.code)
                    if (usableResult) {
                        checkedUsernames.merge({
                            [username]: error.code === 'UserNotFoundException'
                        })
                        iamStageNetwork.dispatch(iamNetDef.CheckingUsername.success)
                    } else {
                        iamStageNetwork.dispatch(iamNetDef.CheckingUsername.failure)
                    }
                })
            return iamNetDef.CheckingUsername
        } else {
            return undefined
        }
    }


    const onSignOut = () => {
        iamApi.signOut()
            .then(user => iamStageNetwork.dispatch(iamNetDef.SigningOut.success))
            .catch(error => iamStageNetwork.dispatch(iamNetDef.SigningOut.failure, error))
    }


    const onRequestForgottenPasswordCode = (username: string) => {
        iamApi.forgotPassword(username)
            .then(user => iamStageNetwork.dispatch(iamNetDef.RequestingForgottenPasswordCode.success))
            .catch(error => iamStageNetwork.dispatch(iamNetDef.RequestingForgottenPasswordCode.failure, error))
    }

    const onResetForgottenPassword = (username: string, newPassword: string, resetCode: string) => {
        iamApi.forgotPasswordSubmit(username, resetCode, newPassword)
            .then(() => iamStageNetwork.dispatch(iamNetDef.ResettingForgottenPassword.success, username, newPassword))
            .catch(error => iamStageNetwork.dispatch(iamNetDef.ResettingForgottenPassword.failure, error))
    }


    const onResetNewPassword = (username: string, oldPassword: string, newPassword: string) => {
        iamApi.signIn(username, oldPassword)
            .then(user => {
                iamApi.completeNewPassword(user, newPassword)
                    .then(() => iamStageNetwork.dispatch(iamNetDef.ResettingNewPassword.success, username, newPassword))
                    .catch(error => iamStageNetwork.dispatch(iamNetDef.ResettingNewPassword.failure, error))

            })
            .catch(error => iamStageNetwork.dispatch(iamNetDef.ResettingNewPassword.failure, error))
    }


    const onRequestEmailConfirmationCode = (username: string) => {
        iamApi.resendSignUp(username)
            .then(() => iamStageNetwork.dispatch(iamNetDef.RequestingEmailConfirmationCode.success))
            .catch(error => iamStageNetwork.dispatch(iamNetDef.RequestingEmailConfirmationCode.failure, error))
    }

    const onConfirmEmailConfirmationCode = (username: string, password: string, confirmationCode: string) => {
        iamApi.confirmSignUp(username, confirmationCode)
            .then(() => iamStageNetwork.dispatch(iamNetDef.ConfirmingEmail.success, username, password))
            .catch(error => iamStageNetwork.dispatch(iamNetDef.ConfirmingEmail.failure, error))
    }


    const onConfirmPhoneConfirmationCode = (confirmationCode: string) => {
        iamApi.currentAuthenticatedUser()
            .then(user => {
                iamApi.verifyUserAttributeSubmit(user, 'phone_number', confirmationCode)
                    .then(() => iamStageNetwork.dispatch(iamNetDef.ConfirmingPhoneCode.success))
                    .catch(error => iamStageNetwork.dispatch(iamNetDef.ConfirmingPhoneCode.failure, error))
            })
            .catch(error => iamStageNetwork.dispatch(iamNetDef.ConfirmingPhoneCode.failure, error))
    }
    const onRequestPhoneConfirmationCode = () => {

        iamApi.currentAuthenticatedUser()
            .then(user => {
                iamApi.verifyUserAttribute(user, 'phone_number')
                    .then(() => {
                        iamStageNetwork.dispatch(iamNetDef.RequestingPhoneConfirmationCode.success)
                    })
                    .catch(error => iamStageNetwork.dispatch(iamNetDef.RequestingPhoneConfirmationCode.failure, error))
            })
            .catch(error => iamStageNetwork.dispatch(iamNetDef.RequestingPhoneConfirmationCode.failure, error))
    }

    const onUpdatePhone = (phone: string) => {
        iamApi.currentAuthenticatedUser()
            .then(user => {
                iamApi.updateUserAttributes(user, {phone_number: phone})
                    .then(() => {
                        iamStageNetwork.dispatch(iamNetDef.UpdatingPhone.success)
                    })
                    .catch(error => iamStageNetwork.dispatch(iamNetDef.UpdatingPhone.failure, error))
            })
            .catch(error => iamStageNetwork.dispatch(iamNetDef.UpdatingPhone.failure, error))
    }


    const onChangePassword = (oldPassword: string, newPassword: string) => {
        iamApi.currentAuthenticatedUser()
            .then(user => {
                iamApi.changePassword(user, oldPassword, newPassword)
                    .then(() => iamStageNetwork.dispatch(iamNetDef.ChangingPassword.success))
                    .catch(error => iamStageNetwork.dispatch(iamNetDef.ChangingPassword.failure, error))
            })
            .catch(error => iamStageNetwork.dispatch(iamNetDef.ChangingPassword.failure, error))
    }

    // return the service instance
    return {
        signBackIn() {
            iamStageNetwork.dispatch(iamNetDef.Unknown.signBackIn)
        },
        signIn(username: string, password: string) {
            const promise = resolvablePromise<void>()
            iamStageNetwork.dispatch(iamNetDef.Anon.signIn, username, password, promise)
            return promise
        },
        signUp(args: SignUpArgs): Promise<void> {
            const promise = resolvablePromise<void>()
            iamStageNetwork.dispatch(iamNetDef.Anon.signUp, args, promise)
            return promise
        },
        signOut() {
            iamStageNetwork.dispatch(iamNetDef.SignedIn.signOut)
        },
        cancelEmailConfirmation() {
            iamStageNetwork.dispatch(iamNetDef.AwaitingEmailConfirmationCode.cancelEmailConfirmation)
        },
        confirmEmailConfirmationCode(username: string, password: string, confirmationCode: string) {
            iamStageNetwork.dispatch(iamNetDef.AwaitingEmailConfirmationCode.confirmEmailConfirmationCode, username, password, confirmationCode)
        },
        requestEmailConfirmationCode(username: string) {
            iamStageNetwork.dispatch(iamNetDef.AwaitingEmailConfirmationCode.requestEmailConfirmationCode, username)
        },

        forgottenPassword() {
            iamStageNetwork.dispatch(iamNetDef.Anon.forgottenPassword)
        },

        requestForgottenPasswordCode(username: string) {
            iamStageNetwork.dispatch(iamNetDef.ForgottenPassword.requestForgottenPasswordCode, username)
            || iamStageNetwork.dispatch(iamNetDef.AwaitingForgottenPasswordCode.requestForgottenPasswordCode, username)
        },
        cancelForgottenPasswordReset() {
            iamStageNetwork.dispatch(iamNetDef.ForgottenPassword.cancelForgottenPasswordReset)
            || iamStageNetwork.dispatch(iamNetDef.AwaitingForgottenPasswordCode.cancelForgottenPasswordReset)
        },
        resetForgottenPassword(username: string, newPassword: string, resetCode: string) {
            iamStageNetwork.dispatch(iamNetDef.AwaitingForgottenPasswordCode.resetForgottenPassword, username, newPassword, resetCode)
        },


        cancelNewPasswordReset() {
            iamStageNetwork.dispatch(iamNetDef.AwaitingNewPassword.cancelNewPasswordReset)
        },

        resetNewPassword(username: string, oldPassword: string, newPassword: string) {
            iamStageNetwork.dispatch(iamNetDef.AwaitingNewPassword.resetNewPassword, username, oldPassword, newPassword)
        },

        confirmPhoneConfirmationCode(confirmationCode: string) {
            iamStageNetwork.dispatch(iamNetDef.AwaitingPhoneConfirmationCode.confirmPhoneConfirmationCode, confirmationCode)
        },

        cancelPhoneConfirmation() {
            iamStageNetwork.dispatch(iamNetDef.AwaitingPhoneConfirmationCode.cancelPhoneConfirmationCode)
        },

        requestPhoneConfirmationCode() {
            iamStageNetwork.dispatch(iamNetDef.AwaitingPhoneConfirmationCode.requestPhoneConfirmationCode)
        },

        updatePhoneNumber(phone: string) {
            iamStageNetwork.dispatch(iamNetDef.AwaitingPhoneNumberUpdate.updatePhoneNumber, phone)
        },

        getCurrentIamStage(): HookState<IamStageState> {
            return iamStageNetwork.currentStage()
        },

        checkUsername(username: string) {
            iamStageNetwork.dispatch(iamNetDef.Anon.checkUsername, username)
        },

        getCheckedUsernames(): HookState<CheckedUsernames> {
            return checkedUsernames
        },

        isBusy(): boolean {
            return IamBusyStages.includes(iamStageNetwork.currentStage().stage.value)
        },
    }
}


function formatError(error: any): IamStageData {

    const formattedError: IamStageData = {
        errorCode: '',
        errorMessage: ''
    }

    if (isString(error)) {
        formattedError.errorCode = 'StringError'
        formattedError.errorMessage = error
    } else if (hasTemporaryPasswordExpired(error)) {
        formattedError.errorCode = 'NotAuthorizedException'
        formattedError.errorMessage = 'Your temporary password has expired, please check your inbox for a new one'
    } else {
        const sanitisedMessage = error.code === 'UserNotFoundException' ? 'Incorrect username or password.' : error.message
        formattedError.errorCode = error.code ?? 'NoErrorCode'
        formattedError.errorMessage = sanitisedMessage ?? 'No error message'
    }

    return formattedError
}

function hasTemporaryPasswordExpired(error: any): boolean {
    return error.code === 'NotAuthorizedException' && error.message.includes('Temporary password has expired and must be reset by an administrator')
}
